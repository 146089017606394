body {
    padding: 0;
}

.aspNetHidden {
    padding: 0;
}

textarea {
    font-size: 1em;
}

input {
    margin-bottom: 1px;
}

#SectionHeader {
    height: 40px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 0;
}

#SectionContent {
    padding-bottom: 0;
}

#floater {
    float: left;
    height: 50%;
    margin-top: -15px;
    padding-bottom: 0;
}

#title {
    clear: both;
    height: 30px;
}

#response {
    overflow: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 0;
}

    #response table {
        color: #333333;
        border-width: 1px;
        border-color: #A8B2B2;
        border-collapse: collapse;
        margin-bottom: 0;
    }

    #response th {
        border-width: 1px;
        border-style: solid;
        border-color: #A8B2B2;
        padding: 8px;
        text-align: center;
        cursor: pointer;
    }

td {
    border-width: 1px;
    border-style: solid;
    border-color: #A8B2B2;
    padding-left: 2px;
}

.allowBreak {
    word-break: break-all;
}

.summaryList {
    border-width: 0;
    margin-bottom: 20px;
    width: 100%;
    table-layout: fixed;
}

    .summaryList td {
        border-width: 0;
        padding-bottom: 0;
    }

.summaryHeader {
    text-align: right;
    font-weight: bold;
    width: 150px;
}

.responsePane {
    margin-left: 1em;
    margin-right: 1em;
    padding-bottom: 0;
}

.oddRow {
    background-color: #CDE6F7; /* 2013 light background color */
}

.tableHeader {
    background-color: #0072C6; /*2013 hero color*/
    color: #FFFFFF;
}

#inputHeaders {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.sectionHeader {
    background-color: #0072C6; /*2013 hero color*/
    color: #FFFFFF;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: bold;
    cursor: pointer;
}

.tableCaption {
    background-color: rgb(234, 236, 238);
    color: #000000;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: bold;
    display: block;
    text-align: left;
    cursor: pointer;
}

.collapsibleArrow {
    float: right;
    cursor: pointer;
}

.collapsibleSwitch {
    float: left;
    font-size: xx-large;
    line-height: 8px;
    display: block;
    width: 38px;
    text-align: center;
    padding-left: 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.collapsibleWrapper {
    padding-bottom: 0;
    margin-bottom: 1em;
    overflow-x: auto;
    overflow-y: hidden;
    word-wrap: break-word;
}

.hiddenElement {
    display: none;
}

.emptyColumn {
    display: none;
}

.hotBarCell {
    padding: 0;
}

.hotBarContainer {
    padding-bottom: 1px;
}

.hotBarBar {
    height: 8px;
    background-color: #E44D4D; /* A nice red */
    padding-bottom: 0;
    border-radius: 3px;
}

.hotBarLabel {
    padding: 0.4em 1.2em 2px 0;
    white-space: nowrap;
}

.negativeCell {
    color: #E44D4D; /* A nice red */
}

#hop {
    min-width: 3em;
}

#number {
    min-width: 2em;
}

#originalHeadersContainer {
    margin-left: 1em;
}

#originalHeaders {
    white-space: pre-wrap;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#mhaFooter {
    float: right;
}

.commandBar {
    height: 44px;
    background-color: #f4f4f4;
    padding: 0;
}

.ms-Button-icon {
    display: inline-block;
}